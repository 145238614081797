import React, { useState, useEffect } from "react";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import NewPlanSelectList from "../components/NewPlanSelectList";
import { Container, Image, Row, Col } from "react-bootstrap";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { saveRouteDetails } from "../redux/actions/routeActions";
import { resetShopState, updateShopObject } from "../redux/actions/shopActions";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
import { api } from "../utilities/api";
import { mapBoxOnePlans, getPartnerIdFromPageLocation, goTo } from "../utilities/utils";
import NeedSimModal from "../components/NeedSimModal";
import { constants } from "../redux/constants";
import { proxyApi } from "../utilities/proxy-api";
import { ApiRequest } from "../models/proxy-api-request";
import { mapPortaOnePlans } from "../utilities/portaone-utils";
import Modal from "../components/Modal";
import PortableModal from "../components/PortableModal";
import { MaintenanceStatusType } from "../types/MaintenanceStatusType";
import { PlansObject } from "../types/PlansObject";

export default function activation({ location }) {
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const shopDetails = useSelector((state: RootState) => state.shop);
  const [isShowPlans, setIsShowPlans] = useState(false);
  const [simCardNumber, setSimCardNumber] = useState("");
  const [plans, setPlans] = useState([]);
  const [hasPlans, setHasPlans] = useState(true);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [promoCodeFromQuery, setPromoCodeFromQuery] = useState("");
  const [validReferral, setValidReferral] = useState(false);
  const applicationDown = shopDetails.maintenance_status === MaintenanceStatusType.ApplicationDown && shopDetails.country === constants.CANADA;

  // show need sim modal, unless application is down.
  const [showNeedSimModal, setShowNeedSimModal] = useState(!applicationDown);

  const [modalVisible, setModalVisible] = useState(false);
  const [planObject, setPlanObject] = useState<PlansObject>({
    Canada: {
      Postpaid: [],
      Prepaid: []
    },
    USA: {
      Prepaid: []
    }
  });

  const isUSA = shopDetails.country === constants.USA;

  const verifyReferral = async (referral) => {
    if (referral) {
      return await api.verifyReferral(referral)
        .then(res => {
          let referrerInfo = JSON.parse(res.data);
          if (Object.keys(referrerInfo).length === 0) {
            dispatchShop({ referral_cnum: "" });
            setValidReferral(false);
            setModalVisible(true);
          } else if (!referrerInfo.SuspensionDate && !referrerInfo.TerminationDate) {
            dispatchShop({ referral_cnum: referral });
            setValidReferral(true);
          }
        })
        .catch(err => console.error(err));
    } else {
      dispatchShop({ referral_cnum: "" });
      setValidReferral(false);
    }
  }

  const onCloseModal = () => {
    goTo('/', {}, true);
  }

  useEffect(() => {
    dispatch(saveRouteDetails("activation") as any);
    handlePromoCodeLink();
    handleReferralLink();
    dispatchShop({ sfID: 0 });
    dispatchShop({ country: constants.CANADA });
    dispatchShop({ delivery_countryId: 0 });
    dispatchShop({ delivery_province: "" });
    dispatchShop({ delivery_address: "" });
    dispatchShop({ delivery_postal: "" });
    dispatchShop({ delivery_needed: true });
    dispatchShop({ delivery_type: 0 });
    dispatchShop({ sfName: "" });
    dispatchShop({ sfFee: 0.0 });
  }, []);

  const openNotificationWithIcon = (type) => {
    if (type === "error") {
      notification[type]({
        message: "Error!",
        description: "Sorry this is an invalid SIM Card Number.",
      });
    } else if (type === "warning") {
      notification[type]({
        message: "Warning!",
        description:
          "Sorry there were no plans found with this SIM Card Number.",
      });
    }
  };

  const handleShowPlans = () => {
    verifySimcard();
  };

  const verifySimcard = () => {
    let realSimNumber;
    if (simCardNumber.slice(-1).toLowerCase() === "f") {
      realSimNumber = simCardNumber.substring(0, simCardNumber.length - 1);
      setSimCardNumber(realSimNumber);
    } else {
      realSimNumber = simCardNumber;
    }

    if (!realSimNumber || realSimNumber.length < 19 || realSimNumber.length > 20) {
      setVerifySuccess(false);
      setVerifyFailed(true);
      return;
    }

    // USA Portaone
    if (shopDetails.country === constants.USA) {

      setVerifySuccess(false);
      setVerifyFailed(false);

      var request = new ApiRequest({ iccid: realSimNumber });
      proxyApi.sim_card.getCardInfo(request).then(res => {
        if (!res.success && res.error) {
          console.error(res.error);
          return;
        }

        const cardInfo = res.data.card_info;
        if (!cardInfo || !cardInfo.i_sim_card || cardInfo.is_used) {
          setIsShowPlans(false);
          setVerifySuccess(false)
          setVerifyFailed(true);
          return;
        }

        // get partner id from description
        const descriptionParts = cardInfo.description.split(',');
        let partnerId = descriptionParts[descriptionParts.length - 1];

        // if activation is coming from an affiliate link in the url https://www.gophonebox.com/activation?i_rep=##
        // then render the partners plans related to the URL affiliate link
        // instead of the partner from the return sim card info in this api call
        // https://gophonebox.atlassian.net/browse/PI-126
        const partnerIds = getPartnerIdFromPageLocation(location);
        if (partnerIds.usPartnerId) {
          partnerId = partnerIds.usPartnerId;
        }

        // set sim card number in store
        dispatchShop({ simcard_no: realSimNumber });
        dispatchShop({ isPostpaid: false });

        setVerifySuccess(true);
        setVerifyFailed(false);
        if (partnerId) {
          proxyApi.plan.getPartnerPlans(new ApiRequest({ i_rep: partnerId })).then(res => {
            if (!res.success && res.error) {
              console.error(res.error);
              return;
            }

            let plans = mapPortaOnePlans(res.data.product_list);
            console.log('FTR: ', plans);
            setPlans([...plans]);
            setHasPlans(plans.length !== 0);
            setIsShowPlans(true);
            dispatchShop({ us_main_i_product: res.data.main_i_product });
            dispatchShop({ us_i_rep: +partnerId });
            dispatchShop({ plans: plans });
          }).catch(err => {
            console.error(err);
          });
          return;
        }

        proxyApi.plan.getDefaultPlans().then(res => {
          if (!res.success && res.error) {
            console.error(res.error);
            return;
          }

          let plans = mapPortaOnePlans(res.data.product_list);
          console.log('FTR: ', plans);
          setPlans([...plans]);
          setHasPlans(plans.length !== 0);
          setIsShowPlans(true);
          dispatchShop({ us_main_i_product: res.data.main_i_product });
          dispatchShop({ us_i_rep: null });
          dispatchShop({ plans: plans });
        }).catch(err => {
          console.error(err);
        });

      }).catch(err => {
        console.error(err);
      });
      return;
    }

    // Canada BoxOne
    api.verifySimCard(realSimNumber).then(async (response) => {
      let data = response.data;

      // CASE for invalid simCardNumber
      if (data === null) {
        // TODO: modify or remove this function if needed.
        // dispatch(resetShopState());
        setIsShowPlans(false);
        setVerifySuccess(false)
        setVerifyFailed(true);
        return;
      }
      setVerifySuccess(true);
      setVerifyFailed(false);

      // if we have primaryPromoCode, then it will replace secondaryPromoCode,
      // if the secondaryPromocode
      let primaryPromoCode = promoCodeFromQuery;
      let isPrimaryPromoCodeValid = false;
      let secondaryPromoCode = data.PromoCode;
      let countryName = getCountryNameFromId(data.CountryId);
      let planType = "";
      let currency = "";
      let country = "";
      let promoCode = "";

      if (validatePromoCode(primaryPromoCode)) {
        planType = primaryPromoCode.charAt(0) === "M" ? constants.POSTPAID_PLAN : constants.PREPAID_PLAN;
        currency = primaryPromoCode.charAt(1) === "C" ? constants.CANADA_CURRENCY : constants.USA_CURRENCY;
        country = primaryPromoCode.charAt(1) === "C" ? constants.CANADA : constants.USA;
        promoCode = primaryPromoCode;
        isPrimaryPromoCodeValid = true;
      } else {
        planType = data.Type;
        currency = data.Currency;
        country = countryName;
        promoCode = secondaryPromoCode;
        isPrimaryPromoCodeValid = false;
      }

      if (planType === constants.POSTPAID_PLAN) {
        dispatchShop({ promocode: promoCode });
      } else {
        dispatchShop({ prepaidPromocode: promoCode });
      }

      // setting simcard no and promo code here, since they will not join the following steps.
      dispatchShop({ simcard_no: realSimNumber });
      dispatchShop({ currency: currency });
      dispatchShop({ country: country });
      dispatchShop({ isPostpaid: planType === constants.POSTPAID_PLAN })

      // clear US promocode until we need it later
      dispatchShop({ usPromocode: '' });

      // if mobile, scroll to the plans section after verification.
      // delay for half a second to allow for API to response times.
      if (mobile) {
        setTimeout(() => { window.scrollTo({ top: 700 }) }, 500)
      }

      console.log('verifySimCard() Primary Promo Code: ', primaryPromoCode);
      console.log('verifySimCard() Primary Promo Code Valid: ', isPrimaryPromoCodeValid);
      console.log('verifySimCard() Secondary Promo Code: ', secondaryPromoCode);
      console.log('verifySimCard() Promo Code: ', promoCode);
      console.log('verifySimCard() Plan Type: ', planType);
      console.log('verifySimCard() Currency: ', currency);
      console.log('verifySimCard() Country: ', country);
      console.log('verifySimCard() SIM: ', realSimNumber);

      // affiliate
      const partnerIds = getPartnerIdFromPageLocation(location);
      let canadaPartnerId = ''
      if (partnerIds.canadaPartnerId) {
        canadaPartnerId = partnerIds.canadaPartnerId.toString();
      }

      console.log("BN and referral: ", canadaPartnerId, validReferral);

      if (canadaPartnerId && !validReferral) {
        api.getPartnerPromoCodeAll(canadaPartnerId).then(res => {   
          if (res && res.data && res.data.isWhls) {
            window.location.href = "https://partner.gophonebox.com/";
            return;
          }       
          if (res && res.data) {
            const promoCode = data.Type === constants.PREPAID_PLAN ? res.data.CANPrepaid : res.data.CANPostpaid;
            console.log("getPartnerPromoCodeAll() Promo Code: ", promoCode);
            console.log("getPartnerPromoCodeAll() Response: ", res.data);
            api.getPlans(data.Carrier, promoCode, data.Currency, data.Type)
              .then(response => {
                let plans = mapBoxOnePlans(response.data, planType);
                console.log("getPlans() Plans Using BN: ", plans);
                // apply SimcardNoCharge status if needed
                if (res.data.USAPrepaid && res.data.USAPrepaid === 'SimcardNoCharge') {
                  dispatchShop({ usSimcardNoCharge: true });
                  dispatchShop({ usPromocode: res.data.USAPrepaid });
                } else {
                  dispatchShop({ usSimcardNoCharge: false });
                  dispatchShop({ usPromocode: '' });
                }
                setPlans([...plans]);
                setHasPlans(plans.length !== 0);
                setIsShowPlans(true);
                dispatchShop({ plans: plans });
              }).catch((error) => {
                console.error(error);
              });
          }
        }).catch((error) => {
          console.error(error);
        });
        return;
      }

      // referral links have priority over any promocode
      if (validReferral) {
        promoCode = "";
      }
      // get plans and show
      api.getPlans(data.Carrier, promoCode, data.Currency, data.Type)
        .then(response => {
          let plans = mapBoxOnePlans(response.data, planType);
          console.log("getPlans() Plans Using Promo Code: ", plans);
          setPlans([...plans]);
          setHasPlans(plans.length !== 0);
          setIsShowPlans(true);
          dispatchShop({ plans: plans });
        }).catch((error) => {
          console.error(error);
        });
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleReferralLink = () => {
    let referral = new URLSearchParams(window.location.search).get(
      "referral"
    );
    verifyReferral(referral);
  }

  function handlePromoCodeLink() {
    let promoCodeTmpStore = new URLSearchParams(window.location.search).get("pc");
    setPromoCodeFromQuery(promoCodeTmpStore);
    if (validatePromoCode(promoCodeTmpStore)) {
      const isPostpaid = promoCodeTmpStore.charAt(0) === "M" ? constants.POSTPAID_PLAN : constants.PREPAID_PLAN;
      const currency = promoCodeTmpStore.charAt(1) === "C" ? constants.CANADA_CURRENCY : constants.USA_CURRENCY;
      const country = promoCodeTmpStore.charAt(1) === "C" ? constants.CANADA : constants.USA;
      if (isPostpaid) {
        dispatchShop({ promocode: promoCodeTmpStore });
      } else {
        dispatchShop({ prepaidPromocode: promoCodeTmpStore });
      }
      dispatchShop({ isPostpaid });
      dispatchShop({ currency });
      dispatchShop({ country });
    }
  }

  const validatePromoCode = (promoCode) => {
    if (!promoCode || promoCode.length < 3) {
      return false;
    }

    // first letter should be M as monthly or, P as prepaid
    if (promoCode.charAt(0) !== "M" && promoCode.charAt(0) !== "P") {
      return false;
    }

    // second letter should be 'C' as CAD or 'U' as USD
    if (promoCode.charAt(1) !== "C" && promoCode.charAt(1) !== "U") {
      return false;
    }

    return true;
  };

  const getCountryNameFromId = (countryId) => {
    switch (countryId) {
      case 247:
        return "USA";
      case 42:
        return "Canada";
      default:
        return "Canada";
    }
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 769) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  useEffect(() => {
    let newPlansObject = {
      Canada: {
        Postpaid: (shopDetails.country === constants.CANADA && shopDetails.isPostpaid) ? plans : [],
        Prepaid: (shopDetails.country === constants.CANADA && !shopDetails.isPostpaid) ? plans : []
      },
      USA: {
        Prepaid: shopDetails.country === constants.USA ? plans : []
      }
    } as PlansObject;

    setPlanObject(newPlansObject);
  }, [plans])

  return (
    <PageLayout>
      <title>SIM Activation | PhoneBox</title>
      {showNeedSimModal && <NeedSimModal setShowModal={setShowNeedSimModal} />}
      <Wrapper>
        <Container>
          <Row>
            <Col md={6} sm={12} className="d-flex justify-content-center align-items-center">
              <LeftContent>
                <Title>
                  {mobile ? (<Trans>Mobile Plans</Trans>) : (
                    <div style={{ fontSize: "25px" }}>
                      <Trans>Activate your SIM Card</Trans>
                    </div>
                  )}
                </Title>
                <Text>
                  <Trans>Input Your SIM Card Number:</Trans>
                </Text>
                <MyInput placeholder="SIM #" value={simCardNumber} onChange={(event) => setSimCardNumber(event.target.value)} />
                {verifyFailed && (
                  <div>
                    <ErrorMessage>
                      <Trans>The SIM card number is invalid!</Trans>
                    </ErrorMessage>
                  </div>
                )}
                {(verifySuccess && plans.length !== 0) && (
                  <div>
                    <ValidMessage>
                      <Trans>The SIM card number you entered is verified.</Trans>
                    </ValidMessage>
                  </div>
                )}
                {(verifySuccess && !hasPlans) && (
                  <div>
                    <ErrorMessage>
                      <Trans>Please check the SIM card number. <br></br> There is no plan associated with the SIM card.</Trans>
                    </ErrorMessage>
                  </div>
                )}
                <div className="text-center">
                  <NextButton onClick={handleShowPlans}>
                    <Trans>Next</Trans>
                  </NextButton>
                  <a onClick={() => goTo('/plans')}>
                    <NoSimcardLink style={{ textDecoration: "underline", marginTop: 0 }}>
                      {mobile ? (
                        <Trans>I don't have a SIM Card</Trans>
                      ) : (
                        <Trans>I don't have a SIM Card/Check our Plans</Trans>
                      )}
                    </NoSimcardLink>
                  </a>
                </div>
              </LeftContent>
            </Col>
            <Col md={6} sm={12} className="d-flex justify-content-center align-items-center my-3 mt-5">
              <div style={{ position: "relative" }}>
                {mobile ? (
                  <>
                    <div style={{ width: "15rem" }}>
                      <Image width="100%" src="/images/activation/sim_card-01_720.png" alt="Activation Sim Card" className="pb-5" fluid />
                    </div>
                  </>
                ) : (
                  <div>
                    <Image width="340px" src="/images/activation/activation-sim.png" alt="Activation Sim Card" className="pb-5" fluid />
                  </div>
                )}
                <div style={{ position: "absolute", top: mobile ? -25 : -40, right: mobile ? -10 : 20 }}>
                  <Image fluid width={mobile ? "50px" : "70px"} src={(isUSA ? "/images/us.png" : "/images/canada.png")} />
                </div>
              </div>
              {!mobile && (
                <p>
                  <Trans>You will find this number</Trans>
                  <br />
                  <Trans>on the back of your SIM card.</Trans>
                </p>
              )}
            </Col>
          </Row>
          <div style={{ height: 40 }}></div>
          {isShowPlans &&
            <NewPlanSelectList country={shopDetails.country} currency={shopDetails.currency} enableESIM={false} plans={planObject} enableShipping={false} initialPlanType={shopDetails.isPostpaid ? "Postpaid" : "Prepaid"} availablePlanTypes={shopDetails.isPostpaid ? ['postpaid'] : ['prepaid']}/>
          }
        </Container>
      </Wrapper>
      {modalVisible && <Modal 
        onClose={onCloseModal}
        body={<div style={{ padding: 10, marginLeft: 20, marginRight: 20 }}>The referral link you are attempting to use is invalid. Please check your link again or receive one from your friend.</div>}
      />}
      {applicationDown &&
        <PortableModal 
          noCloseButton
          body={
            <div style={{ textAlign: 'center', padding: "0px 25px" }}>
              <div style={{ fontWeight: 700, fontSize: "16px", fontFamily: 'Poppins', color: '#0494CA', margin: "30px 0px 20px 0px" }}>Services Temporarily Unavailable</div>
              <div style={{ fontFamily: 'Poppins' }}>Your services will remain unaffected but no new request or activation is possible at this moment.</div>
              <div style={{ fontWeight: 700, fontFamily: 'Poppins', width: "300px", margin: "20px auto 30px auto" }}>Please refresh the page, or come back very soon!</div>
            </div>
          }
          onClose={onCloseModal}
        ></PortableModal>
      }
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: 40px;
`;

const Title = styled.h3`
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Text = styled.p`
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const NoSimcardLink = styled.p`
  font-size: 14px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 16px;
`;

const ValidMessage = styled.div`
  color: green;
  font-size: 16px;
`;

const LeftContent = styled.div`
  display: inline-block;
  margin: auto;
  p,
  a,
  input {
    margin: 20px 0px;
  }
`;

const NextButton = styled.a`
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin: 0px;
  outline: none !important;
  padding: 10px 50px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  :hover {
    color: #fff;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  }
`;

const MyInput = styled.input`
  width: 300px;
  outline: none;
  border: 0px;
  border-radius: 10px;
  letter-spacing: 2px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
`;


export const Head = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          } 
        `
      }}/>
    </>
  )
}